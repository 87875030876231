<template>
  <div :class="containerClass">
    <div
      v-for="(opt, index) in renderOptions"
      :key="index"
      :class="listClassRenderer"
      class="relative inline-flex"
    >
      <input
        :id="`${name}_${index}_${opt.value}`"
        class="peer absolute left-0 top-0 opacity-0"
        type="radio"
        :name="name"
        :disabled="disabled"
        :checked="modelValue === opt.value"
        @change="handleModelUpdate(opt.value)"
      />
      <label
        class="flex cursor-pointer items-center leading-xs font-medium text-xs text-on-surface-elevation-2 px-12"
        :class="labelClass"
        :for="`${name}_${index}_${opt.value}`"
      >
        {{ opt.label }}
      </label>
    </div>

    <div v-if="hasManualInputButton" :class="listClassRenderer" class="relative inline-flex">
      <input
        :id="`inputPriceManual`"
        class="peer absolute left-0 top-0 opacity-0"
        type="radio"
        :name="name"
        :checked="modelValue === 'manual'"
        :disabled="disabled"
        @change="handleModelUpdate('manual')"
      />
      <label
        class="flex cursor-pointer items-center leading-xs font-medium text-xs text-on-surface-elevation-2 px-12"
        :class="labelClass"
        :for="`inputPriceManual`"
      >
        {{ $t('studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_btn') }}
      </label>
    </div>

    <s-icon-button
      v-if="options.length > 12"
      size="sm"
      variant="outline"
      icon="ic-v2-control-arrow-down-line"
      :iconClass="iconButtonClass"
      class="w-32 !rounded-full"
      @click="isOpenedPriceList = !isOpenedPriceList"
    />
  </div>

  <st-error-message :name="fieldName" :showError="showFieldError" />
</template>
<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { FormOption, RadioButtonsProps } from '@/types/common/form.type';

const props = withDefaults(defineProps<RadioButtonsProps>(), {
  outline: false,
  size: 'sm',
  breakListNumber: 0,
  containerClass: '',
  listClass: '',
  hasManualInputButton: false
});

const { rules } = toRefs(props);

const isOpenedPriceList = ref<boolean>(false);

const iconButtonClass = computed(() => {
  return isOpenedPriceList.value ? 'rotate-180' : '';
});

const renderOptions = computed(() => {
  if (!props.breakListNumber || props.breakListNumber >= props.options.length) {
    return props.options;
  }

  if (isOpenedPriceList.value) {
    return props.options;
  }

  return props.options.filter(
    (_: FormOption<string | number>, i: number) => i < props.breakListNumber
  );
});

const labelClass = computed(() => {
  return {
    'h-32': props.size === 'sm',
    'h-36': props.size === 'md',
    'rounded-full bg-[var(--stds-glob-color-gray40)] peer-checked:bg-on-surface-elevation-1 peer-checked:text-surface-elevation-1':
      !props.outline,
    'rounded-lg border-1 border-solid border-[var(--stds-glob-color-gray60)] bg-white peer-checked:border-blue300 peer-checked:bg-blue50 peer-checked:text-brand-primary':
      props.outline
  };
});

const listClassRenderer = computed(() => ({
  'pointer-events-none': props.disabled,
  [`${props.listClass}`]: !!props.listClass
}));

const emit = defineEmits<{
  'update:modelValue': [v: string | number];
}>();

const value = ref(props.modelValue);

const setFieldValue = ref<(value: string | number) => void>();

const fieldName = computed<string>(() => props.name ?? '');
const showFieldError = ref(false);

if (fieldName.value) {
  const { setValue, showError } = useValidation<string | number>({
    fieldName: fieldName.value,
    rules,
    value
  });
  setFieldValue.value = setValue;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

const handleModelUpdate = (e: string | number) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};

watch(
  () => props.modelValue,
  (v?: string | number) => {
    value.value = v;
  }
);
</script>
